import { Link } from "gatsby"
import { string } from "prop-types"
import React from "react"

import ArrowRight from "../icons/arrow_black_right.svg"

type LinkWithArrowProps = {
  url: string
  text: string
  center?: boolean
  blue?: boolean
}

const LinkWithArrow = ({ url, text, center, blue }: LinkWithArrowProps) => {
  let className = "ArrowLink"
  if (center) className += " d-flex justify-content-center"
  if (blue) className += " ArrowLink--blue"

  return (
    <div className={className}>
      <Link to={url} className="link">
        {text} <ArrowRight />
      </Link>
    </div>
  )
}

export default LinkWithArrow
