import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Image = ({ src, divWrapperClass = "w-100", ...props }) => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
        }
      }
    }
  `)

  const match = useMemo(
    () =>
      data.allFile.edges.find(({ node }) => {
        return src === node.publicURL
      }),
    [data, src]
  )

  if (src?.includes(".svg")) {
    return <img src={match?.node.publicURL} {...props} />
  }

  const { onClick, ...restOfProps } = props || {}

  const img = <Img fluid={match?.node.childImageSharp.fluid} {...restOfProps} />

  if (onClick) {
    return (
      <div className={divWrapperClass} onClick={onClick}>
        {img}
      </div>
    )
  }

  return img
}

export default Image
