export enum SectionTypes {
  MediaItem = "MediaItem",
  StepsSection = "StepsSection",
  CardSection = "CardSection",
  VideoSectionWithContent = "VideoSectionWithContent",
  FAQSection = "FAQSection",
  VideoSection = "VideoSection",
  ImageSection = "ImageSection",
  PartnersSection = "PartnersSection",
  ImageWithTwoDescriptions = "ImageWithTwoDescriptions",
  TeamSection = "TeamSection",
  SpecialistSection = "SpecialistSection",
  PressSection = "PressSection",
  TextSection = "TextSection",
  OrderSection = "OrderSection",
  PilotSection = "PilotSection",
  ButtonSection = "ButtonSection",
  Newsletter = "NewsletterSection",
  NumbersSection = "NumbersSection",
  ImagesWithLinkSection = "ImagesWithLinkSection",
  DownloadSection = "DownloadSection",
  WorkInProgress = "WorkInProgress",
  ArticleSection = "ArticleSection",
  SectionTitle = "SectionTitle",
  ResizableAssetWithContent = "ResizableAssetWithContent",
  InstructionSection = "InstructionSection",
  GallerySection = "GallerySection",
}

export enum LinkTypes {
  Link = "link",
  LinkWithArrow = "linkWithArrow",
  ButtonLink = "buttonLink",
  ImageLink = "imageLink",
}

export enum ArticleTypes {
  blogEntry = "blogEntry",
  successStory = "successStory",
  video = "video",
}
