import classNames from "classnames"
import { Link } from "gatsby"
import React from "react"
import { Button } from "reactstrap"
import { LinkTypes } from "../helpers/constants"
import Image from "./Image"
import LinkWithArrow from "./LinkWithArrow"

//default for link with image
const LinkItem = ({
  linkItemData,
  defaultLinkItemData = null,
  fullWidth = false,
  btnClassName = "",
}) => {
  if (!linkItemData) return null
  if (linkItemData.type === LinkTypes.ImageLink) {
    const image = <img src={linkItemData.image.localFile.publicURL} />

    if (linkItemData.isInternal) {
      return <Link to={linkItemData.link}>{image}</Link>
    } else {
      return <a href={linkItemData.link}>{image}</a>
    }
  } else if (linkItemData.type === LinkTypes.LinkWithArrow) {
    return (
      <div className="Manual__media-wrapper__description__link">
        <LinkWithArrow blue url={linkItemData.link} text={linkItemData.text} />
      </div>
    )
  } else if (linkItemData.type === LinkTypes.ButtonLink) {
    const image = defaultLinkItemData.buttonImage
      ? defaultLinkItemData.image.localFile.publicURL
      : null

    const isLeft =
      linkItemData.imagePosition && linkItemData.imagePosition == "left"

    return (
      <Button
        tag={Link}
        to={linkItemData.link}
        color={defaultLinkItemData.buttonTheme || "primary"}
        outline={defaultLinkItemData.outline || false}
        className={classNames(
          {
            "navbar-button--left": isLeft,
            "w-100": fullWidth,
          },
          btnClassName
        )}
      >
        {isLeft && linkItemData.buttonImage && <Image src={image} />}
        {linkItemData.text}
        {!isLeft && linkItemData.buttonImage && <Image src={image} />}
      </Button>
    )
  } else {
    return (
      <Link className="link" to={linkItemData.link}>
        {linkItemData.text}
      </Link>
    )
  }
}

export default LinkItem
